import React from 'react'
import NavBar from '../components/navbar';

function Traffic() {
  return (
    <div>
      <NavBar tcolor={"text-[#099884]"}/>
      Traffic
    </div>
  )
}

export default Traffic;